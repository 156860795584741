import React, { useEffect } from 'react';
// @TODO remove unstable after upgrade to next major release v5
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from '@mui/material/styles';

import { deDE } from '@mui/material/locale';

let theme = createMuiTheme(
  {
    typography: {
      fontFamily: [
        'Lato',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h4: {
        fontSize: '2rem',
      },
      h5: {
        fontWeight: 700,
      },
      h6: {
        fontSize: '1.1rem',
        fontWeight: 700,
        // textTransform: 'uppercase',
        lineHeight: 1.3,
      },
    },
    palette: {
      text: { primary: '#19223c', secondary: '#a0a1ad' },
      primary: {
        main: '#c21632',
        contrastText: '#fff',
      },
      secondary: {
        main: '#448aff',
      },
      background: { default: '#fff' },
    },
    shape: { borderRadius: '4px' },
    components: {
      MuiFormGroup: {
        styleOverrides: {
          // root: {
          //   marginBottom: '16px',
          // },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: '16px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
          },
        },
      },
    },
    overrides: {
      MuiAvatar: {
        rounded: { borderRadius: '14px' },
      },
      MuiPaper: {
        outlined: { border: '2px solid rgba(0,0,0,0.09)' },
      },
      MuiDivider: {
        root: { height: '2px' },
        light: {
          backgroundColor: '#f7f7ff',
        },
        vertical: {
          width: '2px',
        },
      },
      MuiCardContent: {
        root: {
          padding: '48px',
        },
      },
      MuiCardActionArea: {
        focusHighlight: {
          borderRadius: '20px',
          '&:focus': {
            outline: 0,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: '16px',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: '20px',
          padding: '6px 20px',
        },
        text: {
          fontWeight: 700,
          padding: '6px 20px',
        },
        containedSecondary: {
          boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.3)',
          '&:hover': {
            backgroundColor: '#ffe400',
            boxShadow: ' 0px 4px 10px 0px rgba(0,0,0,0.3)',
          },
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            borderRadius: '20px',
          },
        },
      },
    },
  },
  deDE,
);

theme = responsiveFontSizes(theme);

export const Theme = ({ children }) => {
  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
